// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-personal-projects-js": () => import("./../../../src/pages/personal-projects.js" /* webpackChunkName: "component---src-pages-personal-projects-js" */),
  "component---src-pages-recent-work-js": () => import("./../../../src/pages/recent-work.js" /* webpackChunkName: "component---src-pages-recent-work-js" */)
}

